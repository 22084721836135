import { Injectable } from '@angular/core';

import { Disease, DiseaseStatus } from '../../models/Diseases';

@Injectable({
  providedIn: 'root',
})
export class DiseaseService {
  public diseaseStatuses = {
    hep: [
      { id: '1', status: 'Unknown' },
      { id: '2', status: 'Immune' },
      { id: '3', status: 'Susceptible' },
      { id: '4', status: 'Positive' },
    ],
    covid: [
      { id: '5', status: 'Asymptomatic Exposure' },
      { id: '6', status: 'PUI' },
      { id: '7', status: 'Confirmed Positive' },
      { id: '8', status: 'N/A' },
      { id: '9', status: 'TBD' },
      { id: '9', status: 'Recoverd - NOT Tested' },
      { id: '10', status: 'Recovered - Tested' },
      { id: '11', status: 'Recovered' },
      { id: '12', status: 'Resolved' },
    ],
  };

  public covidDiseasesPatientMap: Disease[] = [
    { id: '5', label: 'CV19|EXP', css: 'covid-exp' },
    { id: '6', label: 'CV19|PUI', css: 'covid-pui' },
    { id: '7', label: 'CV19|POS', css: 'covid-pos' },
    { id: '8', label: 'CV19|NA', css: 'covid-na' },
    { id: '9', label: 'CV19|REC', css: 'covid-rec' },
    { id: '10', label: 'CV19|REC', css: 'covid-rec' },
    { id: '11', label: 'CV19|REC', css: 'covid-rec' },
    { id: '12', label: 'CV19|RES', css: 'covid-res' },
  ];

  public covidDiseaseChairMap: Disease[] = [
    { id: '5', label: 'CV19|EXP', reason: 'CV19EXP', css: 'covid-exp' },
    { id: '6', label: 'CV19|PUI', reason: 'CV19PUI', css: 'covid-pui' },
    { id: '7', label: 'CV19|POS', reason: 'CV19POS', css: 'covid-pos' },
    { id: '8', label: 'CV19|NA', reason: 'CV19NA', css: 'covid-na' },
    { id: '9', label: 'CV19|TBD', reason: 'CV19TBD', css: 'covid-tbd' },
  ];

  constructor() {}

  isCovidReason(disease: string) {
    const diseaseObj = this.covidDiseaseChairMap.find((el: Disease) => el.reason === disease);
    return diseaseObj ? true : false;
  }

  getDiseaseById(diseaseType: string, status: string): Disease {
    if (diseaseType.toLowerCase() === 'covid') {
      const disease = this.covidDiseasesPatientMap.find((s) => s.id === status);
      return disease;
    }
  }

  getDiseaseByReason(reason: string): Disease {
    return this.covidDiseaseChairMap.find((el: Disease) => el.reason === reason);
  }

  isDiseaseStatus(diseaseType: string, status: string) {
    const disease = this.diseaseStatuses[diseaseType].find((el: DiseaseStatus) => el.id === status);
    return disease ? true : false;
  }

  // Special check for eCube recovered since we don't want it in the dropdowns
  isEcubeRecovered(status: string) {
    return status === '11';
  }

  // Return the recovered status for the eCube recovered state, so we can
  // inject it into the dropdown when its returned.
  getEcubeRecoveredStatus() {
    return this.diseaseStatuses['covid'].find((el) => el.id === '11');
  }

  checkClinicCapabilities(clinicCapabilities: string) {
    if (!clinicCapabilities) return false;
    const capabilities = clinicCapabilities.split(',');
    let isCapable = false;
    capabilities.forEach((capability) => {
      isCapable = this.isCovidReason(capability);
    });
    return isCapable;
  }
}
