import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';

@Injectable()
export class ZipcodeService {
  constructor(private apiService: ApiService) {}

  lookupZipsByCityState(city: string, state: string) {
    let queryParams = new HttpParams();
    queryParams = queryParams.set('city', city);
    queryParams = queryParams.set('state', state);

    return this.apiService.makeRequest('get', 'zipcode-search', { queryParams, showIndicator: false });
  }
}
