import { DateTime } from 'luxon';
import moment from 'moment';

// Luxon considers Sunday to be the end of the week
export function actualStartOfWeek(date: DateTime) {
  return date.weekday !== 7 ? date.startOf('week').minus({ days: 1 }) : date;
}

export function getDateStringWithTimeZone(date: Date | string): string {
  return moment(date).utcOffset(0, true).format();
}

export function getDateByAddingDaysFromIso(date: string, days: number): string {
  let dateTime: DateTime = DateTime.fromISO(date);
  dateTime = dateTime.set({ hour: dateTime.hour - dateTime.offset / 60 });
  dateTime = dateTime.plus({ day: days });
  return getDateStringWithTimeZone(dateTime.toISO());
}

export function getDateByAddingDaysFromJsDate(date: Date, days: number): string {
  let dateTime: DateTime = DateTime.fromJSDate(date);
  dateTime = dateTime.set({ hour: dateTime.hour - dateTime.offset / 60 });
  dateTime = dateTime.plus({ day: days });
  return getDateStringWithTimeZone(dateTime.toISO());
}

export function getCurrentDateStringWithoutHours(): string {
  return getDateStringWithTimeZone(DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISO());
}

export function getDateStringWithoutHours(date: Date): string {
  return getDateStringWithTimeZone(
    DateTime.fromJSDate(date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISO(),
  );
}

export function getDateWithoutTimeZone(date: Date): Date {
  date.setHours(date.getHours() + date.getTimezoneOffset() / 60);
  return date;
}

export function setDateTimeToZero(date: Date): void {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
}

export function firstDayOfWeek(date: DateTime): DateTime {
  return date.minus({ week: date.weekday === 7 ? 0 : 1 }).set({ weekday: 7 });
}

export function getDateOnlyFromString(date: string) {
  return date.split('T')[0];
}

export function getDatesDifferenceInDays(date1: Date, date2: Date): number {
  const date1Time = date1.getTime();
  const date2Time = date2.getTime();
  const diffTime = Math.abs(date2Time - date1Time);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
}

export function isoStringToDateTime(dateString: string) {
  const justDate = dateString.replace(/-/g, '/').replace(/T.+/, '');
  const jsDate = new Date(justDate);
  return DateTime.fromJSDate(jsDate);
}
