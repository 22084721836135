<div class="main-container">
  <div *ngIf="lockout | async" class="overlay"></div>
  <div class="left">
    <a [routerLink]="['/']" class="logo-container nav">
      <div class="logo-icon"></div>
    </a>
    <div [hidden]="noUser" *ngFor="let nav of navLinks">
      <div *ngIf="nav.hasAccess" class="nav-link">
        <div *ngIf="nav.counter && nav.hasAccess" class="nav-counter">
          <span>{{nav.counter}}</span>
        </div>
        <a *ngIf="nav.hasAccess && nav.url" class="nav info" routerLinkActive="active-link" [routerLink]="nav.url">
          {{nav.displayText}}
        </a>
        <a *ngIf="nav.hasAccess && nav.externalUrl" class="nav info" [href]="nav.externalUrl">{{nav.displayText}}</a>
        <a
          *ngIf="nav.hasAccess && nav.subMenu && !nav.externalUrl  && !nav.url"
          class="nav info"
          routerLinkActive="active-link">
          {{nav.displayText}}
        </a>
        <div *ngIf="nav.hasAccess && nav.subMenu" class="sub-menu-container">
          <div *ngFor="let subNav of nav.subMenu">
            <a
              *ngIf="subNav.hasAccess&& subNav.url"
              routerLinkActive="active-link"
              [routerLink]="subNav.url"
              class="sub-menu-link">
              <a class="sub-menu">{{subNav.displayText}}</a>
            </a>
            <a
              *ngIf="subNav.hasAccess && subNav.externalUrl"
              routerLinkActive="active-link"
              [href]="subNav.externalUrl"
              class="sub-menu-link">
              <a class="sub-menu">{{subNav.displayText}}</a>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="right">
    <div *ngIf="!noUser && showClinicList" class="selected-clinic">
      <div
        class="selected-clinic-nav user-infomations"
        [ngClass]="{'selected-clinic-nav-active': displaySearch}"
        (click)="showSearch()">
        <span class="selected-label">Selected Clinic:</span>
        <div class="selcted-clinic-name">
          <i class="fa-solid fa-location-dot"></i>
          {{selectedClinic?.CenterDisplayName}}
        </div>
      </div>
      <div class="search-clinic" [ngClass]="{'display-search': displaySearch}">
        <div class="search-input-container">
          <span>Quick Jump:</span>
          <div class="search-input">
            <input
              type="text"
              pInputText
              placeholder="Enter Center ID"
              [(ngModel)]="searchClinicInput"
              (ngModelChange)="searchClinicInputChange()"
              (keydown.enter)="goToClinic()" />
            <button
              pButton
              type="button"
              label="Go"
              [disabled]="!centerCodeList?.length"
              (click)="goToClinic()"></button>
          </div>
        </div>
        <div class="search-clinic-container">
          <span *ngIf="searchClinicResult?.length">Available Clinics:</span>
          <div *ngFor="let clinic of searchClinicResult" class="clinic-link">
            <a (click)="selectClinicLink(clinic)">{{clinic?.CenterDisplayName}}</a>
          </div>
          <div class="clinics-number" *ngIf="filterClinicList?.length > 25">
            ... and {{filterClinicList?.length - searchClinicResult?.length}} more.
          </div>
          <span *ngIf="!searchClinicResult?.length" class="clinics-number">No items</span>
        </div>
      </div>
    </div>
    <div class="user-infomations">
      <div *ngIf="!noUser" class="log-out-container">
        <span class="user">{{ userLogin }}</span>
        <a class="sign-out" (click)="logout()">Exit SW</a>
      </div>
      <div [ngClass]="{'spinning': backgroundProcessing}" class="fresesius-logo"></div>
    </div>
  </div>
</div>
