import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api.service';

import { DayNote, IDayHeader } from '../core/models/schedule-wise-staff.models';

@Injectable()
export class DayNotesService {
  constructor(private apiService: ApiService) {}

  public getNotesByDateRange = (centerId: string, startDate: Date, endDate: Date): Observable<any> => {
    const startLux = DateTime.fromJSDate(startDate).toISODate();
    const endLux = DateTime.fromJSDate(endDate).toISODate();
    return this.apiService.makeRequest('get', `staff/center/${centerId}/day-notes`, {
      queryParams: {
        startDate: startLux,
        endDate: endLux,
      },
    });
  };

  public saveNotesByDate = (centerId: string, noteDate: string, notes: string): Observable<any> => {
    return this.apiService.makeRequest('post', `staff/center/${centerId}/day-notes`, {
      queryParams: {
        noteDate: noteDate,
        notes: notes,
      },
    });
  };

  public extractDaysBaseOnSelectedDate(start: Date, end: Date, dayNotes: DayNote[]): IDayHeader[] {
    const daysList: IDayHeader[] = [];
    const d = new Date(start);
    for (d; d <= end; d.setDate(d.getDate() + 1)) {
      const dLux = DateTime.fromJSDate(d);
      let note = null;
      if (dayNotes && dayNotes.length > 0) {
        note = dayNotes.find((n) => {
          const nd = DateTime.fromJSDate(new Date(n.noteDate)).toISODate();
          return nd === dLux.toISODate();
        });
      }
      daysList.push({
        shortDayDisplay: dLux.weekdayShort.slice(0, -1) + '-' + dLux.day,
        longDayDisplay: dLux.toLocaleString(DateTime.DATE_HUGE),
        date: dLux.toISODate() + 'T00:00:00.000Z',
        note,
      });
    }
    return daysList;
  }

  public generateDaysArray(start: Date, end: Date): IDayHeader[] {
    const daysList: IDayHeader[] = [];
    const d = new Date(start);
    for (d; d <= end; d.setDate(d.getDate() + 1)) {
      const dLux = DateTime.fromJSDate(d);
      daysList.push({
        shortDayDisplay: dLux.weekdayShort.slice(0, -1) + '-' + dLux.day,
        longDayDisplay: dLux.toLocaleString(DateTime.DATE_HUGE),
        date: dLux.toISODate() + 'T00:00:00.000Z',
      });
    }
    return daysList;
  }
}
