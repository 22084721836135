import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Route, Router, Routes } from '@angular/router';
import { OktaAuthStateService } from '@okta/okta-angular';
import { appRoutes } from 'src/app/app-routing.module';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-local-index',
  templateUrl: './local-index.component.html',
  styleUrls: ['./local-index.component.scss'],
})
export class LocalIndexComponent implements OnInit {
  username = new UntypedFormControl('');
  password = new UntypedFormControl('');
  userAuth = false;
  loginError = false;
  errorMessage = '';
  redirectUrl: string;
  decodeToken: any;
  public pages: Route[] = [];

  constructor(private router: Router, public ApiService: ApiService, public authStateService: OktaAuthStateService) {}

  ngOnInit(): void {
    this.pages = [];
    this.addRoutesToPages(appRoutes);
  }

  addRoutesToPages(routes: Routes) {
    for (const route of routes) {
      if (route.path.includes('*')) continue;

      if (route?.children?.length > 0) {
        this.addRoutesToPages(route.children);
      } else {
        this.pages.push({ title: route.title, path: route.path });
      }
    }
  }
  public logout() {
    this.router.navigate(['/logout']);
  }
}
