import { Injectable } from '@angular/core';

import { CovidValue } from '../../models/Diseases';
@Injectable()
export class StaticDataService {
  constructor() {}

  get states(): any[] {
    return [
      {
        label: 'Alabama',
        value: 'AL',
      },
      {
        label: 'Alaska',
        value: 'AK',
      },
      {
        label: 'Arizona',
        value: 'AZ',
      },
      {
        label: 'Arkansas',
        value: 'AR',
      },
      {
        label: 'California',
        value: 'CA',
      },
      {
        label: 'Colorado',
        value: 'CO',
      },
      {
        label: 'Connecticut',
        value: 'CT',
      },
      {
        label: 'Delaware',
        value: 'DE',
      },
      {
        label: 'District Of Columbia',
        value: 'DC',
      },
      {
        label: 'Florida',
        value: 'FL',
      },
      {
        label: 'Georgia',
        value: 'GA',
      },
      {
        label: 'Guam',
        value: 'GU',
      },
      {
        label: 'Hawaii',
        value: 'HI',
      },
      {
        label: 'Idaho',
        value: 'ID',
      },
      {
        label: 'Illinois',
        value: 'IL',
      },
      {
        label: 'Indiana',
        value: 'IN',
      },
      {
        label: 'Iowa',
        value: 'IA',
      },
      {
        label: 'Kansas',
        value: 'KS',
      },
      {
        label: 'Kentucky',
        value: 'KY',
      },
      {
        label: 'Louisiana',
        value: 'LA',
      },
      {
        label: 'Maine',
        value: 'ME',
      },
      {
        label: 'Maryland',
        value: 'MD',
      },
      {
        label: 'Massachusetts',
        value: 'MA',
      },
      {
        label: 'Michigan',
        value: 'MI',
      },
      {
        label: 'Minnesota',
        value: 'MN',
      },
      {
        label: 'Mississippi',
        value: 'MS',
      },
      {
        label: 'Missouri',
        value: 'MO',
      },
      {
        label: 'Montana',
        value: 'MT',
      },
      {
        label: 'Nebraska',
        value: 'NE',
      },
      {
        label: 'Nevada',
        value: 'NV',
      },
      {
        label: 'New Hampshire',
        value: 'NH',
      },
      {
        label: 'New Jersey',
        value: 'NJ',
      },
      {
        label: 'New Mexico',
        value: 'NM',
      },
      {
        label: 'New York',
        value: 'NY',
      },
      {
        label: 'North Carolina',
        value: 'NC',
      },
      {
        label: 'North Dakota',
        value: 'ND',
      },
      {
        label: 'Ohio',
        value: 'OH',
      },
      {
        label: 'Oklahoma',
        value: 'OK',
      },
      {
        label: 'Oregon',
        value: 'OR',
      },
      {
        label: 'Pennsylvania',
        value: 'PA',
      },
      {
        label: 'Puerto Rico',
        value: 'PR',
      },
      {
        label: 'Rhode Island',
        value: 'RI',
      },
      {
        label: 'South Carolina',
        value: 'SC',
      },
      {
        label: 'South Dakota',
        value: 'SD',
      },
      {
        label: 'Tennessee',
        value: 'TN',
      },
      {
        label: 'Texas',
        value: 'TX',
      },
      {
        label: 'Utah',
        value: 'UT',
      },
      {
        label: 'Vermont',
        value: 'VT',
      },
      {
        label: 'Virginia',
        value: 'VA',
      },
      {
        label: 'Washington',
        value: 'WA',
      },
      {
        label: 'West Virginia',
        value: 'WV',
      },
      {
        label: 'Wisconsin',
        value: 'WI',
      },
      {
        label: 'Wyoming',
        value: 'WY',
      },
    ];
  }

  get patientTypes(): any[] {
    return [
      {
        label: 'Permanent (ESRD)',
        value: 1,
      },
      {
        label: 'Transient',
        value: 2,
      },
      {
        label: 'Transfer',
        value: 3,
      },
      {
        label: 'AKI (Non-ESRD)',
        value: 4,
      },
      {
        label: 'Disaster',
        value: 5,
      },
    ];
  }

  // We have a status of 11 which is just Recovered, but not
  // showing it in the dropdowns so we arent' including it here.
  get covidTypes(): CovidValue[] {
    return [
      {
        label: 'Close Contact Exposure',
        value: '5',
      },
      {
        label: 'PUI',
        value: '6',
      },
      {
        label: 'COVID-19 Positive',
        value: '7',
      },
      {
        label: 'N/A',
        value: '8',
      },
      {
        label: 'Recovered',
        value: '11',
      },
      {
        label: 'Resolved',
        value: '12',
      },
    ];
  }

  get durationHours(): any[] {
    return [
      {
        label: '1',
        value: 1,
      },
      {
        label: '2',
        value: 2,
      },
      {
        label: '3',
        value: 3,
      },
      {
        label: '4',
        value: 4,
      },
      {
        label: '5',
        value: 5,
      },
      {
        label: '6',
        value: 6,
      },
      {
        label: '7',
        value: 7,
      },
      {
        label: '8',
        value: 8,
      },
      {
        label: '9',
        value: 9,
      },
      {
        label: '10',
        value: 10,
      },
      {
        label: '11',
        value: 11,
      },
    ];
  }

  get durationMinutes(): any[] {
    return [
      {
        label: '0',
        value: 0,
      },
      {
        label: '5',
        value: 5,
      },
      {
        label: '10',
        value: 10,
      },
      {
        label: '15',
        value: 15,
      },
      {
        label: '20',
        value: 20,
      },
      {
        label: '25',
        value: 25,
      },
      {
        label: '30',
        value: 30,
      },
      {
        label: '35',
        value: 35,
      },
      {
        label: '40',
        value: 40,
      },
      {
        label: '45',
        value: 45,
      },
      {
        label: '50',
        value: 50,
      },
      {
        label: '55',
        value: 55,
      },
    ];
  }
}
