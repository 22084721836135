import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OktaAuthGuard } from '@okta/okta-angular';
import { SessionService } from 'src/app/shared/services/session.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent {
  public requireSSO = environment.requireSSO;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sessionService: SessionService,
    private oktaGuard: OktaAuthGuard,
  ) {
    this.logout();
  }

  async logout() {
    await this.sessionService.logout();
    if (environment.requireSSO) {
      window.location.href = environment.ssoHome;
    } else {
      this.router.navigate(['/login']);
    }
  }
}
