import { HttpErrorResponse } from '@angular/common/http';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { init } from '@sentry/angular-ivy';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { hmrBootstrap } from './hmr';

if (environment.ravenEnabled) {
  init({
    dsn: 'https://1f63d38ab0a24a7bb59574c47a9f17b5@sentry.io/3656949',
    environment: environment.env,
    // This is here because otherwise every http error will send a duplicate error it is a known sentry bug
    beforeSend(event, hint) {
      if (hint?.originalException instanceof HttpErrorResponse) {
        return null;
      }

      // if there is a cause object on the error then dump it into the event
      const processedEvent = { ...event };
      const cause = (hint.originalException as Error)?.cause;

      // Error class is handled by sentry, but anything else sentry throws away
      if (cause && !(cause instanceof Error)) {
        processedEvent.extra = {
          ...processedEvent.extra,
          cause,
        };
      }

      return processedEvent;
    },
  });
}

if (environment.production) {
  enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);
// .catch(err => console.log(err));

if (environment.hmr) {
  if (module['hot']) {
    hmrBootstrap(module, bootstrap);
  } else {
    console.error('HMR is not enabled for webpack-dev-server!');
    console.log('Are you using the --hmr flag for ng serve?');
  }
} else {
  bootstrap();
}
