export const environment = {
  production: false,
  local: false,
  hmr: false,
  authApiEndpoint: 'https://qa.fmcschedule.com/services/auth',
  swacApiEndpoint: 'https://qa.fmcschedule.com/services/swac',
  htApiEndpoint: 'https://qa.fmcschedule.com/services/home-therapy',
  floatPoolApiEndpoint: 'https://qa.fmcschedule.com/services/float-pool',
  fmcServicesWebSocket: 'wss://qa.fmcschedule.com/services',
  swUri: 'https://qa.fmcschedule.com',
  trainingUrl: 'https://qa.fmcschedule.com/training.cfm',
  contactUsUrl: 'https://qa.fmcschedule.com/contact.cfm',
  escalationUrl: 'https://qa.fmcschedule.com/escalation.cfm',
  htUri: 'https://qa.fmcschedule.com/home-therapies',
  fmcServicesUrl: 'https://qa.fmcschedule.com/services',
  patientExperienceUrl: 'https://biapps.fmcna.com/schedulewise/#/home',
  gMapsKey: 'AIzaSyDn6HhWp1vyYcChPCodtUE15rVfkux6UyQ',
  dummyCookie: false,
  ravenEnabled: true,
  profileUrl: 'https://myprofile-qa.fmcna.com',
  env: 'qa',
  jwtCookie: 'fmcsrv-qa',
  partnerKey: '%2A%3C%2BNAF%3EV%3E%2EU%3B%22%5F0%20%20%0A',
  oktaIssuer: 'https://myaccessqa.freseniusmedicalcare.com/oauth2/aus21akf60RQlkQfx1d7',
  oktaClientId: '0oaa9mfx9wUoClF7q1d7',
  requireSSO: false,
  ssoHome: 'https://myaccessqa.freseniusmedicalcare.com/',
  wepDashboard:
    'https://app.powerbi.com/groups/me/apps/8901ca7c-9502-44f0-aba4-56f459b87aea/reports/c358b97b-7966-4776-b590-0d6d6b776320/ReportSection48b4f06c9b96573688ff?ctid=19309433-eec0-484d-bde9-dd255cdc1ed6&experience=power-bi',
  doScorecard: 'http://clindshappd01/reports_DOScoreCard.php?report_name=latest&toplevel=area',
  reportsAndAnalytics: 'https://biapps.fmcna.com/schedulewise/#/home/clinic/clinic-tabs/patient-experience',
};
