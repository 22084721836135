import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';

import { RegisterIncompleteModalComponent } from '../register/register-incomplete-modal/register-incomplete-modal.component';
import { DatePickerModalModule } from './../../shared/components/date-picker-modal/date-picker-modal.module';
import { WeekRangePickerModule } from './../../shared/components/week-range-picker/week-range-picker.module';
import { ContainerHeaderComponent } from './container-header/container-header.component';
import { DateRangeComponent } from './date-range/date-range.component';
import { EllipsisTooltipDirective } from './ellipsis-tooltip/ellipsis-tooltip.directive';
import { FloatPoolSelectDropdownComponent } from './float-pool-select-dropdown/float-pool-select-dropdown.component';
import { MainHeaderComponent } from './main-header/main-header.component';
import { DurationPipe } from './pipes/duration.pipe';
import { JobRolePipe } from './pipes/job-role.pipe';
import { PickupTypePipe } from './pipes/pickup-type.pipe';
import { DetailsContentComponent } from './provider-shifts/provider-shift-details/details-content/details-content.component';
import { DetailsFooterComponent } from './provider-shifts/provider-shift-details/details-footer/details-footer.component';
import { DetailsHeaderComponent } from './provider-shifts/provider-shift-details/details-header/details-header.component';
import { ProviderShiftDetailsComponent } from './provider-shifts/provider-shift-details/provider-shift-details.component';
import { ProviderShiftListComponent } from './provider-shifts/provider-shift-list/provider-shift-list.component';
import { ProviderShiftSearchComponent } from './provider-shifts/provider-shift-search/provider-shift-search.component';
import { SubHeaderComponent } from './sub-header/sub-header.component';

@NgModule({
  // only import when a shared component needs
  imports: [
    // angular
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    RouterModule,

    // primeng
    CalendarModule,
    TooltipModule,
    CardModule,
    DropdownModule,
    DialogModule,
  ],

  exports: [
    // angular
    CommonModule,
    ReactiveFormsModule,
    FormsModule,

    // primeng
    TableModule,
    DropdownModule,
    CheckboxModule,
    ButtonModule,
    SelectButtonModule,
    DialogModule,
    InputTextModule,
    InputTextareaModule,
    TooltipModule,
    CardModule,
    RadioButtonModule,
    CalendarModule,
    AutoCompleteModule,
    ConfirmDialogModule,

    // app
    ContainerHeaderComponent,
    DurationPipe,
    JobRolePipe,
    PickupTypePipe,
    DateRangeComponent,
    SubHeaderComponent,
    MainHeaderComponent,
    ProviderShiftSearchComponent,
    ProviderShiftListComponent,
    ProviderShiftDetailsComponent,
    DetailsHeaderComponent,
    DetailsFooterComponent,
    DetailsContentComponent,
    EllipsisTooltipDirective,
    WeekRangePickerModule,
    DatePickerModalModule,
    FloatPoolSelectDropdownComponent,
    RegisterIncompleteModalComponent,
  ],
  declarations: [
    ContainerHeaderComponent,
    DurationPipe,
    JobRolePipe,
    PickupTypePipe,
    DateRangeComponent,
    SubHeaderComponent,
    MainHeaderComponent,
    ProviderShiftSearchComponent,
    ProviderShiftListComponent,
    ProviderShiftDetailsComponent,
    DetailsHeaderComponent,
    DetailsFooterComponent,
    DetailsContentComponent,
    EllipsisTooltipDirective,
    FloatPoolSelectDropdownComponent,
    RegisterIncompleteModalComponent,
  ],
  providers: [],
})
export class SharedModule {}
