import { environment } from 'src/environments/environment';

export function setRedirectUrlBaseOnRole(userCenterRoleCode?: string): string {
  if (environment.local) {
    return '/login/app-list';
  }
  if (userCenterRoleCode === 'SWAC' || userCenterRoleCode === 'SWACREV') {
    return '/swac';
  }
  return '/announcerator';
}
