import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TopHeaderComponent } from './top-header.component';

@NgModule({
  imports: [CommonModule],
  declarations: [TopHeaderComponent],
  exports: [TopHeaderComponent],
})
export class TopHeaderModule {}
