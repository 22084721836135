import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, map } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api.service';
import { ClinicService } from 'src/app/shared/services/clinic.service';

import { ICapacitySettingsData, ICapacitySettingsUploadData, IOpenClinicsResponse } from '../models/capacity-settings';
import { IOldPatient } from '../models/old-patient';
import {
  IGetVirtualLobbyOccupancyResponse,
  IPatientResponse,
  IPatientUploadData,
  IVLPatientData,
} from '../models/patient';

@Injectable({
  providedIn: 'root',
})
export class VirtualLobbyService {
  constructor(
    private apiService: ApiService,
    private clinicService: ClinicService,
    private router: Router,
    private messageService: MessageService,
  ) {}

  private patientRequiresPlacement = new BehaviorSubject<boolean>(false);

  public getPatientRequiresPlacement() {
    return this.patientRequiresPlacement.asObservable();
  }
  public setPatientRequiresPlacement(val: boolean) {
    this.patientRequiresPlacement.next(val);
  }
  public getCapacitySettingsData(clinicId: string | number) {
    return this.apiService.makeRequest<ICapacitySettingsData>('GET', `virtual-lobby/capacitySettings/${clinicId}`);
  }

  public updateCapacitySettingsData(clinicData: ICapacitySettingsUploadData, clinicId: string | number) {
    // make sure we are not sending null to the backend
    for (const i in clinicData) {
      if (clinicData[i] === null) {
        delete clinicData[i];
      }
    }
    return this.apiService.makeRequest('POST', `virtual-lobby/capacitySettings/${clinicId}`, { payload: clinicData });
  }
  public getOpenClinics(clinicId: string | number) {
    return this.apiService.makeRequest<IOpenClinicsResponse>('GET', `virtual-lobby/open-clinics/${clinicId}`);
  }
  public getPatientByMrn(mrn: string) {
    return this.apiService
      .makeRequest<IPatientResponse>('GET', `patients/${mrn}`, {
        errorMessage: 'MRN Lookup failed.',
      })
      .pipe(
        map((res) => {
          return res.patient;
        }),
      );
  }

  public getOccupancy(legacyIdCenter: string | number) {
    return this.apiService.makeRequest<IGetVirtualLobbyOccupancyResponse>('GET', `virtual-lobby/occupancy`, {
      queryParams: { legacyIdCenter },
    });
  }

  public async seperateOccupancy(occupancy: IGetVirtualLobbyOccupancyResponse) {
    const scheduledPatients: IVLPatientData[] = [];
    const transientPatients: IVLPatientData[] = [];
    const requestingPatients: IVLPatientData[] = [];

    for (const patient of occupancy.patientData) {
      if (patient.statusId === 2) {
        scheduledPatients.push(patient);
      } else if (patient.patientTypeId === 2) {
        transientPatients.push(patient);
      } else {
        requestingPatients.push(patient);
      }
    }

    return {
      modified: occupancy.modified,
      scheduledPatients,
      transientPatients,
      requestingPatients,
    };
  }

  public addPatient(patientUploadData: IPatientUploadData) {
    return this.apiService.makeRequest('POST', `virtual-lobby/patient`, {
      payload: {
        notes: patientUploadData.notes,
        startDate: patientUploadData.startDate,
        patientTypeId: patientUploadData.patientType,
        endDate: patientUploadData.endDate,
        numberOfTreatments: patientUploadData.treatments,
        patientDiseaseStatusId: this.joinDiseaseStatus(patientUploadData),
        idCenter: patientUploadData.legacyIdCenter,
        IdPatientMaster: patientUploadData.patientId,
        durationInMinutes: this.convertDuration(patientUploadData),
        scheduleId: patientUploadData.scheduleId,
      },
    });
  }

  public checkPatient(patientUploadData: IPatientUploadData) {
    return this.apiService
      .makeRequest<{ message: string }[]>('get', `virtual-lobby/check-patient`, {
        queryParams: {
          scheduleId: patientUploadData.scheduleId,
          mrn: patientUploadData.mrn,
        },
      })
      .pipe(
        map((res) => {
          if (res?.[0]?.message) {
            this.messageService.add({
              severity: 'error',
              summary: 'Can not add patient',
              detail: `${res?.[0]?.message}`,
            });
            throw new Error(res?.[0]?.message);
          }
        }),
      );
  }

  public editPatient(patientUploadData: IPatientUploadData) {
    return this.apiService.makeRequest('PUT', `virtual-lobby/patient/${patientUploadData.patientLobbyId}`, {
      payload: {
        notes: patientUploadData.notes,
        startDate: patientUploadData.startDate,
        patientTypeId: patientUploadData.patientType,
        endDate: patientUploadData.endDate,
        numberOfTreatments: patientUploadData.treatments,
        patientDiseaseStatusId: this.joinDiseaseStatus(patientUploadData),
        idCenter: patientUploadData.legacyIdCenter,
        IdPatientMaster: patientUploadData.idPatientMaster,
        durationInMinutes: this.convertDuration(patientUploadData),
        scheduleId: patientUploadData.scheduleId,
      },
    });
  }

  public removePatient(patientLobbyId: number) {
    return this.apiService.makeRequest('DELETE', `virtual-lobby/patient/${patientLobbyId}`);
  }

  public placePatient(patientUploadData: IPatientUploadData) {
    return this.apiService.makeRequest('POST', `virtual-lobby/patient/${patientUploadData.patientLobbyId}/place`, {
      payload: {
        idScheduleEvent: patientUploadData.scheduleId,
        startDate: patientUploadData.startDate,
        legacyIdCenter: patientUploadData.legacyIdCenter,
        firstName: patientUploadData.firstName || undefined,
        middleName: patientUploadData.middleName || undefined,
        lastName: patientUploadData.lastName || undefined,
        mrn: patientUploadData.mrn,
        patientDiseaseStatusId: this.joinDiseaseStatus(patientUploadData),
        idPatientMaster: patientUploadData.idPatientMaster,
      },
    });
  }

  public getOpenChairs() {
    return this.apiService.makeRequest('POST', `virtual-lobby/patient/`, {
      payload: {},
    });
  }

  private joinDiseaseStatus(patientUploadData: IPatientUploadData) {
    return `${patientUploadData.hep},${patientUploadData.covidStatus || ''}`;
  }
  private convertDuration(patientUploadData: IPatientUploadData) {
    if (typeof patientUploadData.hour === 'number' && typeof patientUploadData.minute === 'number')
      return patientUploadData.hour * 60 + patientUploadData.minute;
    else return undefined;
  }
  public convertOldPatientToNew(patient: IOldPatient) {
    const output: IVLPatientData = {} as any;

    output.mrn = patient.mrn;

    output.IdPatientMaster = patient.IdPatientMaster;
    output.numberOfTreatments = patient?.transient?.numberOfTreatments;
    output.notes = patient.notes;
    output.patientTypeId = patient.patientTypeId;
    output.startDate = patient.startDate;
    output.endDate = patient?.transient?.endDate;
    output.durationInMinutes = patient?.transient?.durationInMinutes;
    output.patientLobbyId = patient.patientLobbyId;
    output.scheduleId = patient.scheduleId;

    output.firstName = patient.firstName;
    output.middleName = patient.middleName;
    output.lastName = patient.lastName;
    output.mrn = patient.mrn;

    output.patientDiseaseStatusId = patient.patientDiseaseStatusId;

    output.firstName = patient.firstName;
    output.middleName = patient.middleName;
    output.lastName = patient.lastName;
    output.dob = patient.dob;

    return output;
  }
}
