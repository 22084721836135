import { inject } from '@angular/core';
import { Router } from '@angular/router';

import { SessionService } from '../shared/services/session.service';

export const OktaSignInGuard = async () => {
  const sessionService = inject(SessionService);
  const router = inject(Router);

  if (await sessionService.oktaLogin()) {
    return true;
  }
  // wait 4 seconds before continueing to let the okta login work this works
  // kinda like a timeout. If you timeout it will send you to the error page
  await delay(4000);
  return router.parseUrl(`/error`);
};

const delay = (ms) => new Promise((res) => setTimeout(res, ms));
