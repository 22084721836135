import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { MessageService } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

import { Client } from '../../../../node_modules/@hapi/nes/lib/client.js';
import { IQueryParams, IVerbs } from './api.service.js';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  constructor(private sessionService: SessionService, private messageService: MessageService) {}

  private requestsInFlightSubject = new BehaviorSubject<boolean>(false);
  public get requestInFlight() {
    return this.requestsInFlightSubject.asObservable();
  }
  private client = new Client(environment.fmcServicesWebSocket);
  private requestsInFlight = 0;

  private async startRequest() {
    // Turn off heartbeat checking. This will make the websocket only get disconnected when the server physically disconnects
    this.client.onConnect = () => {
      // if something slips through the cracks catch it so the socket does not get disconnected
      this.client.onHeartbeatTimeout = () => {
        throw new Error('ignore me');
      };
      // disable the timeout
      this.client._heartbeatTimeout = false;
      // stop current timeouts
      clearTimeout(this.client._heartbeat);
    };

    // stop people from downloading an absurd number of files at once
    if (this.requestsInFlight >= 3) {
      this.messageService.add({
        life: 10 * 1000, //10 seconds
        severity: 'error',
        summary: `Too many requests`,
        detail: `You have Requested too many files at once. Requesting too many files could negatively impact the servers.`,
      });
      throw new Error('Too many requests');
    }

    this.requestsInFlight++;
    this.requestsInFlightSubject.next(true);
    this.protectUnload(); // this stops people from leaving the page while something is still loading

    // attempt to connect to the client. If already connected an error will be thrown, but will be ignored
    await this.client
      .connect({
        timeout: 1000 * 10, // 10 second timeout for larger files
        auth: {
          headers: {
            authorization: this.sessionService.getUserLogin().jwt,
          },
        },
      })
      .catch((error) => {
        this.messageService.clear();
        // catch errors that happen because we are already connected etc...
        if (error.type !== 'user') {
          console.error(error);
          this.messageService.add({
            life: 10 * 1000, //10 seconds
            severity: 'error',
            summary: `Connection Error`,
            detail:
              'There was an error while connecting to the server. Please try again. If you have already tried multiple times, please reload the page and then try again.',
          });
        }
      });
  }

  private async finishRequest() {
    this.requestsInFlight--;
    if (this.requestsInFlight <= 0) {
      this.requestsInFlightSubject.next(false);
      await this.client.disconnect();
      this.unProtectUnload();
    }
  }

  public async downloadFile(url, name, { params, payload, method = 'get' }: IWebSocketOptions = { method: 'get' }) {
    await this.startRequest();

    try {
      this.messageService.add({
        life: 5 * 1000, //5 seconds
        severity: 'success',
        summary: `Downloading ${name}`,
        detail: 'File download has started. Do not close this page until the download has completed.',
      });

      let paramString = '';
      if (params) {
        let paramMap = new HttpParams();
        for (const key in params) {
          paramMap = paramMap.append(key, params[key]);
        }
        paramString = '?' + paramMap.toString();
      }
      const fileData = await this.client.request({ path: '/services/' + url + paramString, method, payload });

      if (fileData.statusCode === 200) {
        const fetched = await fetch(`data:application/octet-stream;base64,${fileData.payload.file}`);
        const blob = await fetched.blob();
        saveAs(blob, fileData.payload.fileName);

        this.messageService.clear();
        this.messageService.add({
          life: 5 * 1000, //5 seconds
          severity: 'success',
          summary: `${name} downloaded`,
          detail: 'File finished downloading. You can find it in your browsers downloads folder.',
        });
      } else throw fileData;
    } catch (error) {
      console.error(error);
      this.messageService.clear();
      if (typeof error.data == 'string') {
        this.messageService.add({
          life: 10 * 1000, //10 seconds
          severity: 'error',
          summary: `${error.statusCode} Downloading Failure`,
          detail: `Could not download ${name}: ${error.data}`,
        });
      } else {
        this.messageService.add({
          life: 10 * 1000, //10 seconds
          severity: 'error',
          summary: `Downloading Failure`,
          detail: `Failed to download ${name}. Please try again. If you have already tried multiple times, please reload the page and then try again.`,
        });
      }
    }

    await this.finishRequest();
  }

  private beforeunload(event) {
    event.preventDefault();
    // Included for legacy support, e.g. Chrome/Edge < 119
    event.returnValue = true;
  }

  private protectUnload() {
    window.addEventListener('beforeunload', this.beforeunload);
  }
  private unProtectUnload() {
    window.removeEventListener('beforeunload', this.beforeunload);
  }
}

export interface IWebSocketOptions {
  params?: IQueryParams;
  payload?: any;
  method?: IVerbs;
}
