import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OktaAuthGuard } from '@okta/okta-angular';

import { LoggedInGuard } from '../shared/guards/logged-in.guard';
import { LocalIndexComponent } from './index/local-index.component';
import { LoginComponent } from './login/login.component';
import { OktaSignInGuard } from './oktaSignin.guard';
import { PasswordsComponent } from './passwords/passwords.component';
import { SsoRedirectComponent } from './sso-redirect/sso-redirect.component';

const routes: Routes = [
  {
    path: '',
    title: 'login',
    component: LoginComponent,
    pathMatch: 'full',
  },
  {
    title: 'logging in with sso',
    path: 'sso',
    canActivate: [OktaAuthGuard, OktaSignInGuard],
    component: SsoRedirectComponent,
  },
  {
    title: 'Change Passwords',
    path: 'passwords',
    canActivate: [LoggedInGuard],
    component: PasswordsComponent,
  },
  {
    title: 'Fresenius Client - app-list',
    path: 'app-list',
    canActivate: [LoggedInGuard],
    component: LocalIndexComponent,
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NonSSORoutingModule {}
