import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/shared/services/session.service';

import { setRedirectUrlBaseOnRole } from '../helpers/getHome';

@Component({
  selector: 'app-sso-redirect',
  templateUrl: './sso-redirect.component.html',
  styleUrls: ['./sso-redirect.component.scss'],
})

// This just redirects you back home immediatly after logging in
export class SsoRedirectComponent {
  constructor(router: Router, sessionService: SessionService) {
    sessionService.currentUser$.subscribe({
      next: (user) => router.navigate([setRedirectUrlBaseOnRole(user.userCenterRoleCode)]),
    });
  }
}
