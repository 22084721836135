import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

import { ProgressIndicatorService } from '../../services/progress-indicator.service';

@Component({
  selector: 'app-progress-indicator',
  templateUrl: './progress-indicator.component.html',
  styleUrls: ['./progress-indicator.component.scss'],
})
export class ProgressIndicatorComponent implements OnDestroy, OnInit {
  activeSubscription = true;
  subscription: Subscription;

  public visible = true;
  public message = '';

  constructor(private indicatorService: ProgressIndicatorService, private cd: ChangeDetectorRef) {}
  ngOnInit(): void {
    this.createProgressSubscription();
  }

  ngOnDestroy() {
    this.activeSubscription = false;
    this.subscription.unsubscribe();
  }

  createProgressSubscription() {
    this.subscription = this.indicatorService
      .getMessage()
      .pipe(takeWhile(() => this.activeSubscription))
      .subscribe((indicator) => {
        if (indicator) {
          this.message = indicator.message;

          if (indicator.show) {
            this.visible = true;
          } else {
            this.visible = false;
          }
        } else {
          this.message = null;
          this.visible = false;
        }
        this.cd.detectChanges();
      });
  }
}
