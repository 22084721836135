import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { ProgressIndicatorComponent } from './components/progress-indicator/progress-indicator.component';
import { UpperMenuComponent } from './components/upper-menu-component/upper-menu';

@NgModule({
  declarations: [UpperMenuComponent, ProgressIndicatorComponent],
  imports: [CommonModule, InputTextModule, RouterModule, FormsModule, ProgressSpinnerModule],
  exports: [UpperMenuComponent, ProgressIndicatorComponent],
})
export class GlobalSharedModule {}
