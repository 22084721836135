import { Injectable } from '@angular/core';
import { NavigationCancel, NavigationCancellationCode, Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class RouterService {
  constructor(private router: Router, private messageService: MessageService) {}

  public monitor() {
    this.router.events.subscribe((event) => {
      // leave all these comments so that we can see what is there and know what could be used
      // we probably want to use this to handle loading pages
      //
      //
      //
      // if (event instanceof NavigationStart) {
      //   this.isLoading = true;
      // } else if (event instanceof RoutesRecognized) {
      //   console.log('RoutesRecognized event:', event);
      // } else if (event instanceof RouteConfigLoadStart) {
      //   console.log('RouteConfigLoadStart event:', event);
      // } else if (event instanceof RouteConfigLoadEnd) {
      //   console.log('RouteConfigLoadEnd event:', event);
      // } else if (event instanceof NavigationEnd) {
      //   this.isLoading = false;
      // } else
      if (event instanceof NavigationCancel) {
        this.navigationCanceled(event);
      }
      // else if (event instanceof NavigationError) {
      //   this.isLoading = false;
      //   this.errorMessage = 'Navigation error';
      // } else if (event instanceof ChildActivationStart) {
      //   const childRoute = event.snapshot.routeConfig.path;
      //   console.log(`ChildActivationStart event for ${childRoute}`);
      //   // Load data specific to the child component here
      // } else if (event instanceof ChildActivationEnd) {
      //   console.log('ChildActivationEnd event:', event);
      //   // Perform any necessary cleanup tasks here
      // }
    });
  }

  private navigationCanceled(event: NavigationCancel) {
    if (event.code === NavigationCancellationCode.GuardRejected && !event.url.includes('login'))
      this.messageService.add({
        severity: 'warn',
        summary: 'Navigation Canceled',
        detail: `You do not have permission to navigate to ${event.url}.`,
      });
  }
}
