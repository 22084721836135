import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs';

import { ApiService } from './api.service';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class FloatService {
  constructor(private apiService: ApiService, private sessionService: SessionService) {}

  public getRegistrationStatus() {
    return this.sessionService.currentUser$.pipe(
      switchMap((user) => {
        return this.apiService.makeRequest<IRegistrationStatus>(
          'GET',
          `float-pool/staff/${user.staffId}/registration-status`,
        );
      }),
    );
  }
}

export interface IRegistrationStatus {
  status: {
    completedNotification: boolean;
    completedClinics: boolean;
    completedSummary: boolean;
    dismissed: boolean;
    existingStatus: boolean;
  };
  clinics: [];
  notifications: {};
}
