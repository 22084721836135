import { Injectable } from '@angular/core';

import { IStaff, StaffType } from '../core/models/schedule-wise-staff.models';

@Injectable()
export class ScheduleHelper {
  /**
   * Group a list of objects by a specific key
   * let people = [
   * { name: 'Alice', age: 21 },
   * { name: 'Max', age: 20 },
   * { name: 'Jane', age: 20 }
   * ];
   * let groupedPeople = groupBy(people, 'age')
   * {
   *   20: [
   *       { name: 'Max', age: 20 },
   *      { name: 'Jane', age: 20 }
   *   ],
   *   21: [{ name: 'Alice', age: 21 }]
   * }
   * @param objectArray
   * @param property
   */
  public groupBy(objectArray: any, property: any): any {
    return objectArray.reduce((acc, obj) => {
      const key = obj[property];
      if (!key) {
        return acc;
      }
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }

  public createStaffDictionary(staffs: IStaff[]): string[] {
    if (!staffs) {
      return;
    }
    const groupedByStaffType = this.groupBy(staffs, 'staffType');
    const direct = [];
    const indirect = [];
    const staffDictionary = [];
    Object.keys(groupedByStaffType).forEach((key) => {
      /*
          Keep specific order: 'RN', 'PCT', 'LPN' on top,
          then order alphabetically the rest of direct , and then the indirect in alphabetical order also
      */
      switch (key) {
        case 'RN':
          staffDictionary[0] = 'RN';
          break;
        case 'PCT':
          staffDictionary[1] = 'PCT';
          break;
        case 'LPN':
          staffDictionary[2] = 'LPN';
          break;
        default:
          groupedByStaffType[key][0]['type'].toLowerCase() === 'direct'
            ? direct.push(groupedByStaffType[key][0]['staffType'])
            : indirect.push(groupedByStaffType[key][0]['staffType']);
      }
    });
    return staffDictionary.concat(direct.sort(), indirect.sort()).filter((el) => el);
  }

  public createStaffTypesDictionary(types: StaffType[]): string[] {
    if (!types) {
      return;
    }
    const direct = [];
    types.forEach((type: StaffType) => {
      /*
          Keep specific order: 'RN', 'PCT', 'LPN' on top
      */
      switch (type.providerTypeShortValue) {
        case 'RN':
          direct[0] = 'RN';
          break;
        case 'PCT':
          direct[1] = 'PCT';
          break;
        case 'LPN':
          direct[2] = 'LPN';
          break;
        default:
          direct.push(type.providerTypeShortValue);
      }
    });
    return direct.filter((el) => el);
  }

  public hourDifference(
    startTime: string,
    endTime: string,
    shiftBreak: number = 0,
    isROShift: boolean = false,
  ): number {
    if (!startTime || !endTime) {
      return;
    }

    if (isROShift) {
      return 0;
    }

    // all day shift
    if (startTime === '00:00' && endTime === '23:59') {
      return 8.0;
    }

    const end = endTime.split(':');
    const start = startTime.split(':');
    const hours1 = end[0];
    const hours2 = start[0];
    const minutes1 = end[1];
    const minutes2 = start[1];
    let hourDiff = Number(hours1) - Number(hours2);
    let minutesDiff = Number(minutes1) - Number(minutes2);
    if (minutesDiff < 0) {
      minutesDiff = minutesDiff + 60;
      hourDiff--;
    }
    minutesDiff = minutesDiff - shiftBreak;
    if (minutesDiff < 0) {
      minutesDiff = minutesDiff + 60;
      hourDiff--;
    }
    if (hourDiff < 0) {
      hourDiff = 24 + hourDiff;
    }
    if (minutesDiff > 60) {
      hourDiff++;
      minutesDiff = minutesDiff - 60;
    }
    minutesDiff = minutesDiff / 60;
    return Number(hourDiff + minutesDiff);
  }

  public getFractionsOfAnHour(hour: number): string {
    return hour.toFixed(2).toString();
  }

  public isAllDayShift(gridInfo: any): boolean {
    if (!gridInfo) {
      return false;
    }
    if (gridInfo.dtCustom_Start_Time) {
      return gridInfo.dtCustom_Start_Time === '00:00' && gridInfo.dtCustom_End_Time === '23:59';
    } else {
      return gridInfo.dtStart_Time === '00:00' && gridInfo.dtEnd_Time === '23:59';
    }
  }

  public isLongShift(startTime: string, endTime: string, shiftBreak: number = 0) {
    return this.hourDifference(startTime, endTime, shiftBreak) >= 14;
  }

  public getStaffType(staffId: number, allStaffList: IStaff[]) {
    return (
      staffId &&
      allStaffList.filter((staff) => staff.providerId === staffId) &&
      allStaffList.filter((staff) => staff.providerId === staffId)[0] &&
      allStaffList.filter((staff) => staff.providerId === staffId)[0].staffType
    );
  }

  public isNurseShiftAssignedToAPCT(providerRole: string, selectedStaffType: string) {
    return providerRole === 'PCT' && (selectedStaffType === 'RN' || selectedStaffType === 'LPN');
  }

  public isCNMAssignedToPCT(providerRole: string, selectedStaffType: string) {
    return providerRole === 'PCT' && selectedStaffType === 'CNM';
  }

  public isLPNassignToRN(
    providerRole: string,
    selectedStaffType: string,
    shiftsOnTheSameDay: any[],
    allStaffList: IStaff[],
    startTime: string = '',
    endTime: string = '',
  ) {
    if (providerRole !== 'RN' || selectedStaffType !== 'LPN') return false;

    if (!shiftsOnTheSameDay || !startTime || !endTime) {
      return providerRole === 'RN' && selectedStaffType === 'LPN';
    } else {
      const lpnAssignToRN = shiftsOnTheSameDay.filter((shift) => {
        return (
          shift.ProviderRole === 'RN' &&
          shift.intStaff_Id &&
          this.getStaffType(shift.intStaff_Id, allStaffList) === 'RN' &&
          (shift.dtCustom_Start_Time || shift.dtStart_Time) <= startTime &&
          (shift.dtCustom_End_Time || shift.dtEnd_Time) >= endTime
        );
      });
      return lpnAssignToRN && lpnAssignToRN.length ? false : true;
    }
  }

  public isClinicManagerAssignToANurse(providerRole: string, selectedStaffType: string) {
    return providerRole === 'RN' && (selectedStaffType === 'CN' || selectedStaffType === 'CNM');
  }
}
