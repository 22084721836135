//import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { DragDropModule } from 'primeng/dragdrop';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { SelectButtonModule } from 'primeng/selectbutton';

import { GlobalSharedModule } from '../../shared/shared.module';
import { SchedulePatientTreatmentComponent } from './components/schedule-patient-treatment/schedule-patient-treatment.component';
import { ScheduleWisePatientComponent } from './schedule-wise-patient.component';

@NgModule({
  imports: [
    ButtonModule,
    CommonModule,
    DragDropModule,
    DropdownModule,
    InputTextModule,
    MenuModule,
    FormsModule,
    GlobalSharedModule,
    RouterModule,
    SelectButtonModule,
  ],
  declarations: [ScheduleWisePatientComponent, SchedulePatientTreatmentComponent],
})
export class ScheduleWisePatientModule {}
