import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, NgModule, Provider } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { OktaAuthModule, OktaConfig } from '@okta/okta-angular';
import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';
import { createErrorHandler } from '@sentry/angular-ivy';
import { ToastModule } from '@syncfusion/ej2-angular-notifications';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { environment } from 'src/environments/environment';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { DashboardCoreModule } from './dashboard/core/core.module';
import { FloatPoolCoreModule } from './float-pool/core/core.module';
import { NonSSOModule } from './non-sso/non-sso.module';
import { RecoveryModule } from './recovery/recovery.module';
import { ScheduleWiseCoreModule } from './schedule-wise/core/core.module';
import { ScheduleWisePatientModule } from './schedule-wise/schedule-patient/schedule-wise-patient.module';
import { ScheduleWiseStaffModule } from './schedule-wise/schedule-staff/schedule-wise-staff.module';
import { GlobalSharedModule } from './shared/shared.module';
import { StaffCoreModule } from './staff-schedule/core.module';
import { SwacCoreModule } from './swac/core/core.module';

const providers: Provider[] = [];

const authConfig: OktaAuthOptions = {
  issuer: environment.oktaIssuer,
  clientId: environment.oktaClientId,
  redirectUri: window.location.origin + '/client/login/callback',
  scopes: ['openid', 'email', 'profile'],
  pkce: true,
};
const oktaAuth = new OktaAuth(authConfig);
const moduleConfig: OktaConfig = { oktaAuth };

if (environment.ravenEnabled) {
  providers.push({
    provide: ErrorHandler,
    useValue: createErrorHandler({
      extractor: (error, defaultHandler) => {
        if (error instanceof HttpErrorResponse) {
          error = new Error(error.message, { cause: error });
        }
        return defaultHandler(error);
      },
    }),
  });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    SwacCoreModule,
    StaffCoreModule,
    GlobalSharedModule,
    NonSSOModule,
    ScheduleWiseCoreModule,
    FloatPoolCoreModule,
    ToastModule,
    ProgressSpinnerModule,
    OktaAuthModule.forRoot(moduleConfig),
    ReactiveFormsModule,
    FormsModule,
    ScheduleWisePatientModule,
    ScheduleWiseStaffModule,
    DashboardCoreModule,
    RecoveryModule,
  ],
  providers: providers,
  bootstrap: [AppComponent],
})
export class AppModule {}
