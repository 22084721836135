import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/shared/services/api.service';

import { DayMap } from '../../utils/DayUtil';

interface IClinicSearchResults {
  clinics: IClinic[];
}
export interface IClinicInfoResults {
  clinic: IClinic;
}

@Injectable()
export class SwacClinicService {
  constructor(private apiService: ApiService, private messageService: MessageService) {}

  getClinicInfo(idCenter) {
    return this.apiService.makeRequest<IClinicInfoResults>('get', `swac/clinics/${idCenter}`, {}).pipe(
      map((res) => {
        return this.compileScheduleInfo(res.clinic);
      }),
    );
  }

  searchClinics(address: string, radius: number) {
    let queryParams = new HttpParams();
    queryParams = queryParams.set('address', address);
    queryParams = queryParams.set('radius', radius.toString());
    return this.apiService
      .makeRequest<IClinicSearchResults>('get', 'swac/clinic-search', {
        queryParams,
        loadingMessage: 'Searching for clinics',
      })
      .pipe(
        map(
          (c) => {
            let clinics: ICompiledClinic[];
            if (c.clinics.length) {
              clinics = c.clinics.map((clinic) => {
                const compiledClinic = this.compileScheduleInfo(clinic);
                // read info from facilityInfo and set values in the coresponding clinic fields
                compiledClinic.numberOfIsoChairs = compiledClinic.facilityInfo.numberOfIsoChairs;
                compiledClinic.numberOfNonIsoChairs = compiledClinic.facilityInfo.numberOfNonIsoChairs;
                compiledClinic.numberOfLicensedStations = compiledClinic.facilityInfo.numberOfLicensedStations;
                compiledClinic.clinicDays = compiledClinic.facilityInfo.clinicDays;
                if (compiledClinic.atCapacityStatus > 0) {
                  compiledClinic.atCapacity = true;
                } else {
                  compiledClinic.atCapacity = false;
                }
                return compiledClinic;
              });
              return clinics;
            } else {
              this.messageService.add({
                severity: 'warn',
                summary: `No Results`,
                detail: `No clinics found for ${address}`,
              });
            }
            return clinics;
          },
          (err) => {
            console.error('search-clinics-err', err);
          },
        ),
      );
  }

  private compileScheduleInfo(clinic: IClinic) {
    if (!clinic) return;

    const compiledClinic = clinic as ICompiledClinic;

    const scheduleCounts = {};
    let blockedCount = 0;

    compiledClinic.schedules.forEach((s) => {
      s.linkedDayScheduleLabel = s.weekDays.map((wi) => DayMap[wi]).join('');
      scheduleCounts[s.linkedDayScheduleLabel] = scheduleCounts[s.linkedDayScheduleLabel] || 0;
      scheduleCounts[s.linkedDayScheduleLabel] += s.blocked ? 0 : 1;
      blockedCount += s.blocked ? 1 : 0;
    });

    let total = 0;
    const label = Object.keys(scheduleCounts)
      .reduce((strs, key) => {
        const val = scheduleCounts[key];
        strs.push(`${key}: ${val}`);
        total += val;
        return strs;
      }, [])
      .sort()
      .join(' ');

    const scheduleSets = Object.keys(scheduleCounts);
    scheduleSets.sort();
    compiledClinic.scheduleAvailabilityBlocked = !total;
    compiledClinic.standAlone = !compiledClinic.idCenter && compiledClinic.homeInd === 'Y';
    compiledClinic.scheduleDetails = { label, total, scheduleSets, blockedCount };
    compiledClinic.scheduleSets = this.groupSchedulesByCommonProps(compiledClinic.schedules);

    Object.keys(compiledClinic.scheduleSets).forEach((sk) => {
      const scheduleSet = compiledClinic.scheduleSets[sk];
      const { podName, chairName, shiftNumber, startTime, endTime } = scheduleSet[0];

      const paddedSet = [];

      scheduleSets.forEach((s) => {
        let match = false;

        scheduleSet.forEach((ss) => {
          if (s === ss.linkedDayScheduleLabel) {
            paddedSet.push(ss);
            match = true;
          }
        });

        if (!match) paddedSet.push({ podName, chairName, shiftNumber, startTime, endTime });
      });

      compiledClinic.scheduleSets[sk] = paddedSet;
    });

    return compiledClinic;
  }

  private groupSchedulesByCommonProps = (schedules) => {
    return schedules.reduce((acc, curr) => {
      acc[curr.shiftId] = acc[curr.shiftId] || [];
      acc[curr.shiftId].push(curr);
      acc[curr.shiftId].sort((o1, o2) => {
        return o1.weekDayId - o2.weekDayId;
      });
      return acc;
    }, {});
  };
}

export interface ICompiledClinic extends IClinic {
  scheduleAvailabilityBlocked: boolean;
  standAlone: boolean;
  scheduleDetails: { label: string; total: number; scheduleSets: string[]; blockedCount: number };
  scheduleSets: IScheduleSet;
  schedules: ICompiledClinicShedule[];
}

export interface IClinic {
  numberOfIsoChairs: number;
  numberOfNonIsoChairs: number;
  numberOfLicensedStations: number;
  clinicDays: string;
  atCapacity: boolean;
  acuteInd: string;
  addressLine1: string;
  addressLine2: string;
  ambulnzInd: string;
  ambulnzVehicleType: null;
  atCapacityStatus: number;
  city: string;
  clinicCapabilities: null;
  clinicId: string;
  clinicName: string;
  county: string;
  distance: string;
  facilityInfo: {
    numberOfLicensedStations: number;
    numberOfIsoChairs: number;
    numberOfNonIsoChairs: number;
    clinicDays: 'MWF,TTS' | 'TTS' | 'MWF';
  };
  fax: string;
  homeInd: string;
  idCenter: number;
  inCenterInd: string;
  latitude: string;
  longitude: string;
  otherLocalName: string;
  phone: string;
  schedules: IClinicShedule[];
  state: string;
  tcuInd: string;
  zip: string;
  notes: string;
  virtualLobbySettings: IVirtualLobbySettings;

  // This might never actually be here... but it is in the code
  phoneNumber?: string;
}

export interface ICompiledClinicShedule extends IClinicShedule {
  scheduleLabel: string;
  selected: boolean;
  covidWarningReason: string;
  linkedDayScheduleLabel: string;
}

export interface IClinicShedule {
  SQLServerDayofWeek: number;
  blocked: number;
  centerCode: string;
  chairId: number;
  chairName: string;
  endTime: string;
  idCenter: number;
  isNocturnal: number;
  podId: number;
  podName: string;
  reason: string;
  scheduleId: number;
  shiftEventId: string;
  shiftId: string;
  shiftNumber: number;
  startTime: string;
  stationTypeLC: string;
  weekDayId: number;
  weekDays: [1, 3, 5] | [2, 4, 6];
}

export interface IScheduleSet {
  [shiftId: string]: ICompiledClinicShedule[];
}

export interface IVirtualLobbySettings {
  bariatric: boolean;
  bed: boolean;
  chairAvailable: string;
  clinicID: number;
  contactFirstName: string;
  contactLastName: string;
  contactPhoneNumber: string;
  dateModified: string;
  divertClinicID: number;
  divertClinic?: IDivertClinic;
  hepB: boolean;
  hoyer: boolean;
  hoyerCapacity: '300' | '400' | '500';
  lifeVest: boolean;
  lvad: boolean;
  modifiedByUserID: number;
  nocturnal: boolean;
  notes: string;
  peds: boolean;
  stretcher: boolean;
  tcu: boolean;
  trach: boolean;
  vent: boolean;
}

export interface IDivertClinic {
  address: string;
  areaId: number;
  city: string;
  clinicCode: string;
  clinicId: number;
  clinicName: string;
  country: string;
  financialId: string;
  kronos: boolean;
  legacyIdCenter: number;
  modalityId: number;
  nocturnal: boolean;
  operationsId: number;
  phoneNumber: string;
  pilot: boolean;
  postalCode: string;
  state: string;
  swacExclude: boolean;
  testing: boolean;
  training: boolean;
  userId: number;
}
