import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MaintenanceService } from 'src/app/shared/services/maintenance.service';
import { SessionService } from 'src/app/shared/services/session.service';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
})
export class MaintenanceComponent implements OnInit, OnDestroy {
  public message: SafeHtml = '<strong>Retrieving message now</strong>';
  private check: NodeJS.Timer;
  private loggedOut = false;

  constructor(
    private sessionService: SessionService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private maintenanceService: MaintenanceService,
  ) {}
  ngOnDestroy(): void {
    clearInterval(this.check);
  }

  ngOnInit(): void {
    this.getMaintenance();
    this.checkMaintenance();
    this.check = setInterval(
      () => {
        this.checkMaintenance();
      },
      //  every 30 seconds
      1000 * 30,
    );
  }

  checkMaintenance() {
    this.maintenanceService.getStatus().subscribe({
      next: (res) => {
        if (!res) {
          this.router.navigate(['/']);
        } else {
          this.logoutUser();
        }
      },
    });
  }

  private logoutUser() {
    if (this.loggedOut) return;

    // Log the person out to clear everything This might not be neccessary, but it will clear the upper menu
    // and make it easier with cold fusion. Cold fusion will get angry at you if you dont log in again
    this.sessionService.logout(true);
  }

  getMaintenance() {
    this.maintenanceService.getInfo().subscribe({
      next: (res) => {
        if (res.MessageType === 1) {
          this.message = this.sanitizer.bypassSecurityTrustHtml(res.ShortDelayMessage);
        } else {
          this.message = this.sanitizer.bypassSecurityTrustHtml(res.ScheduledDownTimeMessage);
        }
      },
    });
  }
}
