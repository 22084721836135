import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { MessageService } from 'primeng/api';

@NgModule({
  imports: [CommonModule, BrowserModule, BrowserAnimationsModule, HttpClientModule, ReactiveFormsModule],
  providers: [MessageService, CookieService],
})
export class ScheduleWiseCoreModule {}
