import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

import { SessionService } from '../services/session.service';

export const LoggedInGuard = async () => {
  const sessionService = inject(SessionService);
  const router = inject(Router);

  if (await sessionService.tryLogin()) {
    return true;
  }
  // wait 4 seconds before continueing to let the okta login work this works
  // kinda like a timeout. If you timeout it will send you to the error page
  await delay(4000);
  // send them to the fail page otherwise

  if (environment.requireSSO) {
    return router.parseUrl(`/error`);
  } else {
    return router.parseUrl(`/login?returnUrl=${window.location.pathname}`);
  }
};

const delay = (ms) => new Promise((res) => setTimeout(res, ms));
