import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DateTime } from 'luxon';
import { getDateOnlyFromString } from 'src/app/shared/helpers/dateTime';
import { announceratorNotification } from 'src/app/shared/interfaces/announcements';
import { AnnouncementService } from 'src/app/shared/services/announcement.service';

@Component({
  selector: 'app-announcerator',
  templateUrl: './announcerator.component.html',
  styleUrls: ['./announcerator.component.scss'],
})
export class AnnounceratorComponent implements OnInit {
  public announcements: announceratorNotification[];

  constructor(private announceratorService: AnnouncementService, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.announceratorService.getAnnouncements().subscribe({
      next: (announcements) => {
        announcements = announcements.filter(
          (announcement) =>
            // Enabled
            announcement.enabled &&
            // after the start date
            (!announcement.startDate || announcement.startDate <= getDateOnlyFromString(DateTime.local().toISO())) &&
            // before the end date
            (!announcement.endDate || announcement.endDate >= getDateOnlyFromString(DateTime.local().toISO())),
        );

        this.announcements = announcements;
      },
    });
  }
}
