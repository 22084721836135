<div class="flex flex-column align-items-center">
  <h1>Change your password</h1>
  <form (submit)="changePassword()" class="flex flex-column align-items-center">
    <label class="mr-1 mt-2" for="currentPassword">Current Password</label>
    <input type="password" id="currentPassword" name="currentPassword" [(ngModel)]="currentPassword" />

    <label class="mr-1 mt-2" for="newPassword">New Password</label>
    <input type="password" id="newPassword" name="newPassword" [(ngModel)]="newPassword" />

    <label class="mr-1 mt-2" for="newConfirm">Re-type Your Password</label>
    <input type="password" id="newConfirm" name="newConfirm" [(ngModel)]="newConfirm" />

    <p-button class="my-4" type="submit" [disabled]="!currentPassword || !newPassword || newPassword !== newConfirm">
      Change Password
    </p-button>
  </form>
</div>
