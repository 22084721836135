import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';

import { WeekRangePickerComponent } from './week-range-picker.component';

@NgModule({
  imports: [CommonModule, CalendarModule, FormsModule],
  declarations: [WeekRangePickerComponent],
  exports: [WeekRangePickerComponent],
  providers: [],
})
export class WeekRangePickerModule {}
