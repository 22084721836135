import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { DateTime } from 'luxon';
import { firstValueFrom } from 'rxjs';
import { VirtualLobbyService } from 'src/app/virtual-lobby/services/virtual-lobby.service';

import { ClinicService } from '../services/clinic.service';
import { SessionService } from '../services/session.service';

export const VirtualLobbyJailGuard = async (_route: ActivatedRouteSnapshot) => {
  if (
    // this is only relevant to VL jail people
    inject(SessionService).checkUserPermissionsAny(['VLJAIL'])
  ) {
    const vlService = inject(VirtualLobbyService);
    const clinicService = inject(ClinicService);
    const router = inject(Router);
    clinicService.getClinic().subscribe({
      next: async (clinic) => {
        // They are already in the jail page so ignore this
        if (window.location.pathname.includes('virtual-lobby')) {
          return;
        }
        const occupancy = await firstValueFrom(vlService.getOccupancy(clinic.IdCenter));

        const tomorrow = DateTime.now().plus({ day: 1 });
        const tomorrowString = tomorrow.toFormat('MM-dd-yyyy');
        for (const patientData of occupancy.patientData) {
          if (
            patientData.statusId == 2 &&
            (patientData.startDate === tomorrowString ||
              DateTime.fromFormat(patientData.startDate, 'MM-dd-yyyy') < tomorrow)
          ) {
            vlService.setPatientRequiresPlacement(true);
            return router.navigate(['virtual-lobby']);
          }
        }
      },
    });
  }

  // if you have someone in virtual lobby jail this service will re-direct you to the Virtual lobby page
  // so just return true always so that we have a quick response if the person should not be in jail
  return true;
};
