<div class="container">
  <div class="flex flex-column">
    <div class="flex flex-row text-center justify-content-center">
      <h1 class="text-container">
        Schedulewise is under construction. This page will reload on its own once it is available again.
      </h1>
    </div>
    <div class="flex flex-row justify-content-center">
      <img class="construcion-image" src="assets/icon/site-under-construction.svg" />
    </div>
    <div class="flex flex-row text-center justify-content-center">
      <h4 class="text-container" [innerHTML]="message"></h4>
    </div>
  </div>
</div>
