import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';

import { PatientsService } from '../patients/patients.service';
import { AuthInterceptor, NoopInterceptor } from './interceptors';
import { ErrorHandlerService } from './services/error-handler.service';
import { MessageService as SWACMessageService } from './services/message.service';
import { StaticDataService } from './services/static-data.service';
import { SwacClinicService } from './services/swac-clinic.service';
import { ZipcodeService } from './services/zipcode.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastModule,
    ButtonModule,
    ConfirmDialogModule,
  ],
  exports: [HttpClientModule, ToastModule, ConfirmDialogModule],
  providers: [
    MessageService,
    SWACMessageService,
    StaticDataService,
    ZipcodeService,
    PatientsService,
    ConfirmationService,
    SwacClinicService,
    CookieService,
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NoopInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  declarations: [],
})
export class SwacCoreModule {}
