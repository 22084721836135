import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceService {
  constructor(private apiService: ApiService) {}

  getInfo() {
    return this.apiService.makeRequest<maintenanceResponse>('get', 'maintenance/info');
  }
  getStatus() {
    return this.apiService.makeRequest<boolean>('get', 'maintenance/');
  }
  updateInfo(short, scheduled, messageType) {
    return this.apiService.makeRequest('post', 'maintenance/info', {
      payload: {
        short,
        scheduled,
        messageType,
      },
    });
  }
  updateStatus(maintActive) {
    return this.apiService.makeRequest('post', 'maintenance', { payload: { status: maintActive } });
  }
}

export interface maintenanceResponse {
  MaintenanceMode: boolean;
  Modified_IdUsers: number;
  DateModified: string;
  ShortDelayMessage: string;
  ScheduledDownTimeMessage: string;
  MessageType: IMessageType;
  UserName: string;
}

export enum IMessageType {
  scheduled,
  short,
}
