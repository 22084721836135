import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { GlobalSharedModule } from '../shared/shared.module';
import { AnnounceratorComponent } from './announcerator/announcerator.component';
import { ErrorComponent } from './error/error.component';
import { RecoveryHomeComponent } from './home/home.component';
import { LoginRedirectComponent } from './login-redirect/login-redirect.component';
import { LogoutComponent } from './logout/logout.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';

@NgModule({
  declarations: [
    AnnounceratorComponent,
    ErrorComponent,
    LogoutComponent,
    LoginRedirectComponent,
    MaintenanceComponent,
    RecoveryHomeComponent,
  ],
  imports: [RouterModule, CommonModule, GlobalSharedModule],
  exports: [AnnounceratorComponent],
})
export class RecoveryModule {}
