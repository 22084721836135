import { Router } from '@angular/router';

export function routeSomewhere(returnUrl: string, router: Router) {
  if (returnUrl.includes('/client/')) {
    const navigateUrl = returnUrl.replace('/client', '');
    router.navigateByUrl(navigateUrl);
  } else {
    window.location.href = returnUrl;
  }
}
