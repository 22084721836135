import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProgressIndicatorService {
  progressSubject: BehaviorSubject<{
    show: boolean;
    message?: string;
  }> = new BehaviorSubject({ show: false });
  count = 0;

  constructor() {}

  show(message?: string) {
    if (this.count === 0) this.progressSubject.next({ show: true, message });
    this.count++;
  }

  hide() {
    if (this.count === 1) this.progressSubject.next({ show: false });
    this.count--;
  }

  getMessage() {
    return this.progressSubject.asObservable();
  }
}
