import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-passwords',
  templateUrl: './passwords.component.html',
  styleUrls: ['./passwords.component.scss'],
})
export class PasswordsComponent {
  public currentPassword = '';
  public newPassword = '';
  public newConfirm = '';

  constructor(private apiService: ApiService, private messageService: MessageService) {}

  public changePassword() {
    this.apiService
      .makeRequest('POST', 'auth/update-password', {
        payload: {
          oldPassword: this.currentPassword,
          newPassword: this.newPassword,
        },
        ignoreErrors: true,
      })
      .subscribe({
        next: () => {
          this.messageService.add({ severity: 'success', detail: 'Password successfully changed' });
        },
        error: (error) => {
          this.messageService.add({
            severity: 'error',
            summary: `${error.status} Error`,
            detail: `${error.statusText}: Failed to update password`,
          });
        },
      });
  }
}
