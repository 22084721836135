import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { SelectItem } from 'primeng/api';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/shared/services/api.service';
import { SessionService } from 'src/app/shared/services/session.service';
import { environment } from 'src/environments/environment';

import { Clinic, ClinicResponse } from '../core/models/Clinic';
import { FloatPoolUser } from '../core/models/FloatPoolUser';
import { PoolResponse } from '../core/models/Pool';
import { Response, ResponseResponse } from '../core/models/Response';
import { PublishShift, ShiftResponse, UnpublishShift } from '../core/models/Shift';
import { Staff, StaffByIdResponse, StaffResponse } from '../core/models/Staff';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  reloadSelectedClinics$ = new Subject();
  selectedClinics$: BehaviorSubject<Clinic[]> = new BehaviorSubject([]);
  currentClinic$: BehaviorSubject<any>;

  constructor(private api: ApiService, private sessionService: SessionService) {
    this.currentClinic$ = new BehaviorSubject({
      label: '',
      value: null,
    });
  }

  public addClinicToManager(managerId, clinicId, floatId) {
    return this.api.makeRequest('put', `float-pool/managers/${managerId}/clinics/${clinicId}/float/${floatId}`);
  }

  public removeClinicFromManager(managerId, clinicId, floatId) {
    return this.api.makeRequest('delete', `float-pool/managers/${managerId}/clinics/${clinicId}/float/${floatId}`);
  }

  // TODO: combine into one and specify q or groupIds
  public clinicSearchSysAdmin(query, floatId: number, userId: number): Observable<Clinic[]> {
    return this.api
      .makeRequest<ClinicResponse>('get', `float-pool/clinics/float/${floatId}/managers/${userId}`, {
        queryParams: new HttpParams().append('q', query),
        showIndicator: false,
      })
      .pipe(map((c) => c.clinics));
  }

  public getClinicsByGroupIds(groupIds): Observable<Clinic[]> {
    return this.api
      .makeRequest<ClinicResponse>('get', 'float-pool/clinics', {
        queryParams: new HttpParams().append('groupIds', groupIds),
        showIndicator: false,
      })
      .pipe(map((c) => c.clinics));
  }

  public searchClinics(search): Observable<any[]> {
    const queryParams = new HttpParams().append('address', search.address).append('radius', search.radius);

    return this.api
      .makeRequest<any>('get', 'float-pool/clinics/clinic-search', { queryParams })
      .pipe(map((c) => c.clinics));
  }

  public saveClinic(clinicId, staffId): Observable<any> {
    return this.api.makeRequest('put', `float-pool/staff/${staffId}/clinics/${clinicId}`);
  }

  public saveClinicList(clinicList, staffId): Observable<any> {
    return this.api.makeRequest('put', `float-pool/staff/${staffId}/cliniclist/`, { payload: { clinicList } });
  }

  public removeClinicList(clinicList, staffId): Observable<any> {
    return this.api.makeRequest('delete', `float-pool/staff/${staffId}/cliniclist/`, { payload: { clinicList } });
  }

  public removeClinic(clinicId, staffId): Observable<any> {
    return this.api.makeRequest('delete', `float-pool/staff/${staffId}/clinics/${clinicId}`);
  }

  public getSavedClinics(staffId): Observable<any> {
    return this.api.makeRequest<any>('get', `float-pool/staff/${staffId}/clinics`).pipe(map((c) => c.clinics));
  }

  public getShifts(startDate: string, endDate: string, clinicId: number, shiftStatus: number) {
    const queryParams = new HttpParams()
      .append('startDate', startDate)
      .append('endDate', endDate)
      .append('shiftStatus', shiftStatus.toString());
    return this.api.makeRequest<ShiftResponse>('get', `float-pool/clinics/${clinicId}/shifts`, { queryParams }).pipe(
      map((s) => {
        s.shifts = s.shifts.map((shift) => {
          shift.selected = false;
          if (shift.pickupTypeId === 0) {
            shift.pickupTypeId = 1;
          }
          return shift;
        });
        return s;
      }),
    );
  }

  public getStaffByShift(poolId: number, shiftId: number, shiftDate: string): Observable<Staff[]> {
    const queryParams = new HttpParams().append('shiftDate', shiftDate);
    return this.api
      .makeRequest<StaffResponse>('get', `float-pool/managers/pools/${poolId}/shifts/${shiftId}/eligible-staff`, {
        queryParams,
      })
      .pipe(map((s) => s.staff));
  }

  public getStaffDetailsByShift(shiftId: number, staffId: number, shiftDate: string): Observable<Staff> {
    const queryParams = new HttpParams().append('shiftDate', shiftDate);
    return this.api
      .makeRequest<any>('get', `float-pool/staff/${staffId}/shifts/${shiftId}`, { queryParams })
      .pipe(map((s) => s.staffDetails));
  }

  public removeStaffFromShift(shiftId: number, staffId: number, shiftDate: string): Observable<{}> {
    shiftDate = moment(shiftDate).utc().format('YYYY-MM-DD');
    const queryParams = new HttpParams().append('shiftDate', shiftDate);
    return this.api.makeRequest<any>('delete', `float-pool/shifts/${shiftId}/staff/${staffId}`, { queryParams });
  }

  public saveShiftNote(shiftId, note, shiftDate) {
    return this.api.makeRequest('patch', `float-pool/shifts/${shiftId}`, {
      payload: {
        path: '/note',
        value: note,
      },
      queryParams: new HttpParams().append('shiftDate', shiftDate),
    });
  }

  public publishShifts(shifts: PublishShift[], notify = false, stat = false) {
    return this.api.makeRequest<any>('post', 'float-pool/shifts/publish', { payload: { shifts, notify, stat } });
  }
  public unpublishShifts(shifts: UnpublishShift[]) {
    return this.api.makeRequest<any>('post', 'float-pool/shifts/unpublish', { payload: shifts });
  }

  public getShiftResponses(shiftId, shiftDate): Observable<Response[]> {
    shiftDate = moment(shiftDate).utc().format('YYYY-MM-DD');

    return this.api
      .makeRequest<ResponseResponse>('get', `float-pool/shifts/${shiftId}/responses`, {
        queryParams: new HttpParams().append('shiftDate', shiftDate),
      })
      .pipe(map((r) => r.responses));
  }

  public approveResponse(shiftId, shiftDate, staffId) {
    return this.api.makeRequest('put', `float-pool/shifts/${shiftId}/staff/${staffId}`, {
      queryParams: new HttpParams().append('shiftDate', shiftDate),
    });
  }

  public clinicSearch(query): Observable<SelectItem[]> {
    const userId = this.sessionService.currentUser$.value.userId;
    return this.api
      .makeRequest<ClinicResponse>('get', 'float-pool/clinics', {
        queryParams: new HttpParams().append('q', query).append('userId', userId),
        showIndicator: false,
      })
      .pipe(
        map((c) =>
          c.clinics.map((clinic) => {
            return {
              label: `float-pool/${clinic.centerName} (${clinic.centerCode})`,
              value: clinic.centerId,
            };
          }),
        ),
      );
  }

  public getClinicsByManager(floatId): Observable<Clinic[]> {
    return this.api.makeRequest<ClinicResponse>('get', `float-pool/float/${floatId}/clinics`).pipe(
      map((c) =>
        c.clinics.map((clinic) => {
          clinic.selected = false;
          return clinic;
        }),
      ),
    );
  }
  public saveFloatPools(managerId, name): Observable<PoolResponse> {
    return this.api.makeRequest<PoolResponse>('put', `float-pool/managers/${managerId}/name/${name}`);
  }

  public updateFloatPools(managerId, floatId, name): Observable<PoolResponse> {
    return this.api.makeRequest<PoolResponse>('post', `float-pool/managers/${managerId}/float/${floatId}/name/${name}`);
  }

  public removeFloatPool(floatId, managerId): Observable<PoolResponse> {
    return this.api.makeRequest<PoolResponse>('delete', `float-pool/float/${floatId}/manager/${managerId}`);
  }

  public putStaffOnShift(shiftId, staffId, shiftDate) {
    return this.api.makeRequest('put', `float-pool/shifts/${shiftId}/staff/${staffId}`, {
      queryParams: new HttpParams().append('shiftDate', shiftDate),
    });
  }

  public getScheduledShifts(poolId, startDate, endDate) {
    return this.api.makeRequest('get', `float-pool/managers/pool/${poolId}/staff-shifts`, {
      queryParams: new HttpParams().append('startDate', startDate).append('endDate', endDate),
    });
  }

  public getStaffInPool(floatId): Observable<Staff[]> {
    return this.api.makeRequest<StaffResponse>('get', `float-pool/float/${floatId}/staff`).pipe(map((s) => s.staff));
  }

  public getEmployeeById(employeeNumber: string, floatId): Observable<Staff> {
    return this.api
      .makeRequest<StaffByIdResponse>('get', `float-pool/staff/float/${floatId}`, {
        ignoreErrors: true,
        queryParams: new HttpParams().append('employeeNumber', employeeNumber),
      })
      .pipe(map((s) => s.staff));
  }

  public putStaffOnPool(managerId, staffId, floatId) {
    return this.api.makeRequest('put', `float-pool/managers/${managerId}/float/${floatId}/employee/${staffId}`);
  }

  public removeStaffFromPool(managerId, staffId, floatId) {
    return this.api.makeRequest('delete', `float-pool/managers/${managerId}/staff/${staffId}/float/${floatId}`);
  }

  public downloadFloatShiftReport(floatId, startDate, endDate) {
    return window.open(
      `${environment.floatPoolApiEndpoint}/reports/float-staff-schedule?floatId=${floatId}&startDate=${startDate}&endDate=${endDate}`,
    );
  }

  public getFloatPoolsByManager(managerId): Observable<PoolResponse> {
    return this.api.makeRequest<PoolResponse>('get', `float-pool/managers/${managerId}/pools`);
  }
  public getManagerById(managerId, poolId): Observable<FloatPoolUser[]> {
    return this.api.makeRequest<FloatPoolUser[]>('get', `float-pool/managers/${managerId}/pools/${poolId}`);
  }

  public putManagerOnPool(poolId, adminId) {
    const userId = this.sessionService.currentUser$.value.userId;
    return this.api.makeRequest('put', `float-pool/managers/${userId}/pools/${poolId}/admin/${adminId}`);
  }

  public removeManagerFromPool(adminId, poolId) {
    const userId = this.sessionService.currentUser$.value.userId;
    return this.api.makeRequest('delete', `float-pool/managers/${userId}/pools/${poolId}/admin/${adminId}`);
  }

  public getManagersByPoolId(poolId): Observable<Staff[]> {
    return this.api.makeRequest<Staff[]>('get', `float-pool/managers/pools/${poolId}`);
  }

  public postFloatShifts(managerId, startDate: string, endDate: string, clinicIds: string) {
    const queryParams = new HttpParams()
      .append('startDate', startDate)
      .append('endDate', endDate)
      .append('clinicIds', clinicIds);
    return this.api.makeRequest('put', `float-pool/managers/clinic/${managerId}`, { queryParams });
  }

  public postAutFloatShifts(managerId, startDate: string, endDate: string, clinicIds: string, staffId, shiftId) {
    const queryParams = new HttpParams()
      .append('startDate', startDate)
      .append('endDate', endDate)
      .append('staffId', staffId)
      .append('clinicIds', clinicIds)
      .append('shiftId', shiftId);
    return this.api.makeRequest('put', `float-pool/managers/clinic/${managerId}/post`, { queryParams });
  }

  public getFloatConfig(startDate: string, endDate: string, clinicId: string) {
    const queryParams = new HttpParams()
      .append('startDate', startDate)
      .append('endDate', endDate)
      .append('clinicIds', clinicId);
    return this.api.makeRequest('get', `float-pool/staff/clinic`, { queryParams });
  }

  public setDirtyFlag(managerId, clinicId, startDate: string, endDate: string, newClinicId) {
    const queryParams = new HttpParams()
      .append('startDate', startDate)
      .append('endDate', endDate)
      .append('newClinicId', newClinicId);
    return this.api.makeRequest('put', `float-pool/managers/${managerId}/clinic/${clinicId}/dirty`, { queryParams });
  }
}
