import { inject, NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';
import { OktaCallbackComponent } from '@okta/okta-angular';

import { environment } from '../environments/environment';
import { AnnounceratorComponent } from './recovery/announcerator/announcerator.component';
import { ErrorComponent } from './recovery/error/error.component';
import { RecoveryHomeComponent } from './recovery/home/home.component';
import { LoginRedirectComponent } from './recovery/login-redirect/login-redirect.component';
import { LogoutComponent } from './recovery/logout/logout.component';
import { MaintenanceComponent } from './recovery/maintenance/maintenance.component';
import { LoggedInGuard } from './shared/guards/logged-in.guard';
import { OktaCanMatch } from './shared/guards/okta.guard';
import { VirtualLobbyJailGuard } from './shared/guards/virtual-lobby-jail.guard';
import { SessionService } from './shared/services/session.service';

export const appRoutes: Routes = [
  {
    title: 'recovery',
    path: '',
    component: RecoveryHomeComponent,
    // dont let this route get hit if we are on the base route like '/client/'
    canMatch: [(_route, matchedRoute: UrlSegment[]) => matchedRoute.length > 0],
    children: [
      {
        title: 'Okta Redirect',
        path: 'login/callback',
        component: OktaCallbackComponent,
      },
      {
        title: 'Error',
        path: 'error',
        component: ErrorComponent,
      },
      {
        title: 'Logout',
        path: 'logout',
        component: LogoutComponent,
      },
      {
        title: 'Maintenance',
        path: 'maintenance',
        component: MaintenanceComponent,
      },
      {
        title: 'Non-SSO Login',
        path: 'login',
        // this will only show up if we dont require SSO
        canMatch: [() => !environment.requireSSO],
        loadChildren: () => import('./non-sso/non-sso.module').then((m) => m.NonSSOModule),
      },
    ],
  },
  {
    path: '',
    canMatch: environment.requireSSO
      ? [
          // if you are trying to hit '' you cant match this route,
          // because we want to point the users a specific route which the ** below will hit
          (_route, matchedRoute: UrlSegment[]) => matchedRoute.length > 0,
          // This is the Okta library login to check if they are logged into Okta
          OktaCanMatch,
          // check if the person is logged it on our servers
          LoggedInGuard,
        ]
      : // if we aren't requireing sso dont send people to the Okta Login page
        [(_route, matchedRoute: UrlSegment[]) => matchedRoute.length > 0, LoggedInGuard],
    // check if we need to send this person to Virtual lobby jail. It will only activate once when going into this path
    canActivate: [VirtualLobbyJailGuard],
    children: [
      {
        title: 'Login',
        path: 'login',
        canMatch: [() => environment.requireSSO],
        // This route will just send you back home
        component: LoginRedirectComponent,
      },
      {
        title: 'Schedule',
        path: 'schedule',
        canMatch: [() => inject(SessionService).checkUserPermissionsAny(['SS', 'PATIENT'])],
        loadChildren: () => import('./schedule-wise/home/home.module').then((m) => m.HomeModule),
      },
      {
        title: 'Staff Schedule',
        path: 'staff-schedule',
        canMatch: [
          () =>
            inject(SessionService).checkUserPermissionsAny(['_MYSCHEDULE']) || inject(SessionService).userIsSysAdmin(),
        ],
        loadChildren: () => import('./staff-schedule/home/home.module').then((m) => m.HomeModule),
      },
      {
        title: 'Float Pool',
        path: 'float-pool',
        loadChildren: () => import('./float-pool/home/home.module').then((m) => m.HomeModule),
      },
      {
        title: 'Swac',
        path: 'swac',
        canMatch: [() => inject(SessionService).checkUserPermissionsAny(['SWAC', 'SWACPERM'])],
        loadChildren: () => import('./swac/home/home.module').then((m) => m.HomeModule),
      },
      {
        title: 'Admin',
        path: 'admin',
        canMatch: [
          () =>
            inject(SessionService).checkUserRole(['SA', 'JRSA']) ||
            inject(SessionService).checkUserPermissionsAny(['MAINT', 'REPORTS', 'REPORTSNEW']),
        ],
        loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
      },
      {
        title: 'Feature Flags',
        path: 'feature-flags',
        canMatch: [() => inject(SessionService).checkUserRole(['SA', 'JRSA'])],
        loadChildren: () => import('./feature-flags/feature-flags.module').then((m) => m.FeatureFlagsModule),
      },
      {
        title: 'WISE',
        path: 'dashboard',
        canMatch: [() => inject(SessionService).checkUserPermissionsAny(['LP'])],
        loadChildren: () => import('./dashboard/home/home.module').then((m) => m.HomeModule),
      },
      {
        title: 'Pod Configuration',
        path: 'podconfig',
        canMatch: [() => inject(SessionService).checkUserRole(['SA', 'JRSA'])],
        loadChildren: () => import('./podconfig/podconfig.module').then((m) => m.PodConfigModule),
      },
      {
        title: 'Virtual Lobby',
        path: 'virtual-lobby',
        canMatch: [() => inject(SessionService).checkUserPermissionsAny(['LOBBY', 'ROLOBBY'])],
        loadChildren: () => import('./virtual-lobby/virtual-lobby.module').then((m) => m.VirtualLobbyModule),
      },
      {
        title: 'Modal',
        path: 'modal',
        loadChildren: () => import('./modal/modal.module').then((m) => m.ModalModule),
      },
      {
        title: 'Announcerator',
        path: 'announcerator',
        component: AnnounceratorComponent,
      },
    ],
  },
  // catch all to send you back to the home page
  {
    path: '**',
    redirectTo: environment.requireSSO ? '/announcerator' : '/login',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      // uncomment this if you want tracing
      // { enableTracing: environment.local },
      // If you are reading this please comment out enable tracing it should not be pushed to dev
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
