import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DateTime } from 'luxon';
import { map } from 'rxjs';

import { getDateOnlyFromString } from '../helpers/dateTime';
import { announceratorNotification } from '../interfaces/announcements';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class AnnouncementService {
  constructor(private apiService: ApiService, private sanitizer: DomSanitizer) {}

  public filterAnnouncements() {}

  public getAnnouncements(attachUser?: boolean) {
    let queryParams = new HttpParams();
    if (attachUser) {
      queryParams = queryParams.append('attachUser', attachUser);
    }

    return this.apiService
      .makeRequest<announceratorNotification[]>('get', 'admin/announcerator', {
        queryParams,
      })
      .pipe(
        map((announcements) => {
          for (const announcement of announcements) {
            announcement.safeHtml = this.sanitizer.bypassSecurityTrustHtml(announcement.notificationContentHTML);
            announcement.startDate = announcement.startDate && getDateOnlyFromString(announcement.startDate);
            announcement.endDate = announcement.endDate && getDateOnlyFromString(announcement.endDate);

            announcement.startDateObj = announcement.startDate && DateTime.fromISO(announcement.startDate).toJSDate();
            announcement.endDateObj = announcement.endDate && DateTime.fromISO(announcement.endDate).toJSDate();
          }

          announcements.sort((a, b) => a.sortOrder - b.sortOrder);

          return announcements;
        }),
      );
  }

  public newAnnouncement(announcement: announceratorNotification) {
    return this.apiService.makeRequest<announceratorNotification[]>('post', 'admin/announcerator', {
      payload: {
        notificationName: announcement.notificationName,
        notificationContentHtml: announcement.notificationContentHTML,
        startDate: announcement.startDate,
        endDate: announcement.endDate,
        sortOrder: announcement.sortOrder,
        enabled: announcement.enabled,
      },
    });
  }

  public updateAnnouncement(announcement: announceratorNotification) {
    return this.apiService.makeRequest<announceratorNotification[]>(
      'post',
      `admin/announcerator/${announcement.notificationID}`,
      {
        payload: {
          notificationName: announcement.notificationName,
          startDate: announcement.startDate,
          endDate: announcement.endDate,
          sortOrder: announcement.sortOrder,
          enabled: announcement.enabled,
        },
      },
    );
  }

  public updateAnnouncementHtml(announcement: announceratorNotification) {
    return this.apiService.makeRequest<announceratorNotification[]>(
      'post',
      `admin/announcerator/${announcement.notificationID}/content`,
      {
        payload: {
          notificationContentHtml: announcement.notificationContentHTML,
        },
      },
    );
  }
  deleteAnnoncement(notificationID: number) {
    return this.apiService.makeRequest<announceratorNotification[]>('delete', `admin/announcerator/${notificationID}`);
  }
}
