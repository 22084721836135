import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { ClinicDetail, lowerCaseClinicInfo } from 'src/app/dashboard/shared/clinic.models';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ClinicService {
  private $clinicChange = new Subject<ClinicDetail>();

  constructor(private cookieService: CookieService, private apiService: ApiService) {}

  public getClinic() {
    return this.$clinicChange.asObservable();
  }
  public setClinic(clinic: ClinicDetail) {
    this.$clinicChange.next(clinic);
  }

  public getPreselectedClinic() {
    return (
      Number(this.cookieService.get('LCENTER')) || Number(sessionStorage.getItem('fsw.angular._FMCSW_CENTERID')) || null
    );
  }

  public getClinicInfo({
    clinicId,
    centerCode,
    legacyIdCenter,
  }: {
    clinicId?: string;
    centerCode?: string;
    legacyIdCenter?: string;
  }) {
    const obj: any = {};

    if (clinicId) obj.clinicId = clinicId;
    if (centerCode) obj.centerCode = centerCode;
    if (legacyIdCenter) obj.legacyIdCenter = legacyIdCenter;

    return this.apiService.makeRequest<lowerCaseClinicInfo>('get', `clinics/info`, {
      queryParams: obj,
    });
  }
}
