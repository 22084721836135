import { ErrorHandler, Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable()
export class ErrorHandlerService implements ErrorHandler {
  constructor(private messageService: MessageService) {}

  handleError(error: any): void {
    throw error;
  }
}
