<div class="container login-container">
  <h1 style="text-align: center">Enter Valid QA Credentials</h1>
  <form class="login-form">
    <div class="form-section">
      <label>
        Username:
        <input type="text" [formControl]="username" />
      </label>
    </div>
    <div class="form-section">
      <label for="password">
        Password:
        <input type="password" [formControl]="password" />
      </label>
    </div>
    <div class="form-section">
      <button class="app-button signin-button" (click)="submitLogin()">Sign In</button>
    </div>
  </form>
  <a class="app-button" [routerLink]="['/login/sso']">Login with sso</a>
</div>

<div class="container" *ngIf="loginError">
  <p class="error-msg">{{ errorMessage }}</p>
</div>
