export enum cookies {
  swSessionId = 'SW_USER',
  swServer = 'SW_SERVER',
}

export interface IApiOptions {
  endpoint?: string;
  displayToast?: boolean;
  baseUrl?: string;
  showIndicator?: boolean;
  errorMessage?: string;
}
