import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

class Message {
  public type: string;
  public message: any;
}

@Injectable()
export class MessageService {
  subject: Subject<Message>;

  constructor() {
    this.subject = new Subject<Message>();
  }

  sendMessage(msg: Message) {
    this.subject.next(msg);
  }

  getMessage(): Observable<Message> {
    return this.subject.asObservable();
  }
}
