import { Component } from '@angular/core';

import { RouterService } from './shared/services/router.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'fmc-schedulewise-client';

  constructor(routerService: RouterService) {
    routerService.monitor();
  }
}
