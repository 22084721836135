import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';

import { DatePickerModalComponent } from './date-picker-modal.component';

@NgModule({
  imports: [CommonModule, ButtonModule, CalendarModule, DialogModule, FormsModule],
  declarations: [DatePickerModalComponent],
  exports: [DatePickerModalComponent],
})
export class DatePickerModalModule {}
